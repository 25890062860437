import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Restaurant Review App Template | Restaurant App UI | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/restaurant-review-mobile-app/"
    metaDescription="Bring your vision to life with Uizard, the easy-to-use design tool. Use our restaurant review template as it is or update it to suit your vision."
    description="
    h2:Food Finder: A restaurant review app design template for mobile
    <br/>
    Working on a brand-new restaurant review app for mobile devices? Our new mobile app design template, Food Finder, is the perfect solution for your project. All our <a:https://uizard.io/templates/mobile-app-templates/>app design</a> templates come with the must-have UX considerations baked in, meaning you can use the template straight out of the box or use Uizard's drag-and-drop editor to customize the design quickly and easily.

    <br/>
    h3:Bring your ideas to life with real-time collaboration
    <br/> 
    Whether you are working alone or with a broad team of stakeholders, Uizard is here to support you every step of the way with your <a:https://uizard.io/ux-design/>UX/UI design</a> project. Use Uizard's notes system to leave quick feedback or invite new team members with the click of a button, you can even share previews of your project with prospective customers.
    "
    pages={[
      "A sleek and stylish restaurant listing screen with average review score visualization",
      "Restaurant landing page with 'add review' user flow and restaurant detail",
      "Mockup search functionality with food type categorization system",
      "User review example with star rating system mockup",
    ]}
    projectCode="pbwVmKW3g9tJ4QBo5aMq"
    img1={data.image1.childImageSharp}
    img1alt="Restaurant review mobile app summary"
    img2={data.image2.childImageSharp}
    img2alt="Restaurant review mobile app listing screen showing average review score"
    img3={data.image3.childImageSharp}
    img3alt="Restaurant review landing page screen"
    img4={data.image4.childImageSharp}
    img4alt="Mockup search functionality screen"
    img5={data.image5.childImageSharp}
    img5alt="Restaurant review user screen with star rating"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/restaurant-review-mobile-app/restaurant-review-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/restaurant-review-mobile-app/restaurant-review-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/restaurant-review-mobile-app/restaurant-review-app-restaurant-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/restaurant-review-mobile-app/restaurant-review-app-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/restaurant-review-mobile-app/restaurant-review-app-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
